import TextLogo from './assets/logoWithText.png';
import './App.css';
import Navbar from './Navbar';
import About from './About';

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <Navbar />
        </header>
        <div id='Container'>
            <img className='Text-logo' src={TextLogo} alt="Logo with Text"/>
            <About />
        </div>
    </div>
  );
}

export default App;
