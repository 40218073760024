import Navbar from "./Navbar";

function PageNotFound() {
    return (
        <div>
            <Navbar />
            <div id='Container'>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </div>
    );
}

export default PageNotFound;