import Navbar from "./Navbar";
import './Rules.css';

const yaml = require('js-yaml');

function beautifyString(string) {
    return string.trim().replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());
}

const getRules = () => {
    return fetch('rules.yml')
        .then(response => response.text())
        .then(data => {
            // Process the rules.yml data here
            return yaml.load(data);
            // Process the YAML data here
        })
        .catch(error => {
            // Handle any errors that occur during the fetch
            console.error(error);
        });
}

async function getFormattedRules() {
    console.log("Getting Rules");
    try {
        const rules = await getRules();

        console.log(rules);

        // Format the rules data here
        let ruleNumber = 1;
        let formattedRules = "";

        for (const ruleSection in rules) {
            formattedRules += "<div class='Rules-section'>\n";
            console.log(ruleSection);
            formattedRules += "<h1 class='Rules-section-title'>" + beautifyString(ruleSection) + "</h1>\n";
            formattedRules += "<ol type='1' start='" + ruleNumber + "' class='Rules-section-list'>\n";
            for (const rule in rules[ruleSection]) {
                console.log(rule);
                if (typeof rules[ruleSection][rule] === 'object') {
                    // Handle the rule as an object
                    
                    var ruleObject = rules[ruleSection][rule];
                    var key = Object.keys(ruleObject)[0];

                    formattedRules += "<li>" + key + "\n";

                    formattedRules += "\n<ol type='a' class='Rules-section-sublist'>\n";
                    for (const subRule in rules[ruleSection][rule][key]) {
                        formattedRules += 
                            "<li class='Rules-section-item'><i>" 
                            + rules[ruleSection][rule][key][subRule]
                            + "</i></li>";
                    }
                    formattedRules += "</ol>";

                    formattedRules += "</li>";
                } else {
                    // Handle the rule as a string
                    formattedRules += "<li class='Rules-section-item'>" + rules[ruleSection][rule] + "</li>\n";
                }

                ruleNumber++;
            }
            formattedRules += "</ol>\n";
            formattedRules += "</div>\n";
        }
        console.log("Formatted Rules: ", formattedRules);
        return formattedRules;
    } catch (error) {
        // Handle any errors that occur during the fetch
        console.error(error);
        throw error; // Re-throw the error to be handled by the caller
    }
}

function HtmlRules() {
    getFormattedRules().then(data => {
        // console.log("Formatted Rules: ", data);
        document.getElementById('Rules-container').innerHTML = data;
    }).catch(error => {
        console.error("Error fetching formatted rules: ", error);
    });

    return (
        <>
            <Navbar />
            <div className="Rules" id='Container'>
                <h1 className="Rules-title">Rhaya Rules</h1>
                <p className="Rules-intro">
                    Rules and scenarios can be perceived with different interpretations.
                    Thus rules and punishments can and will be flexible and applied differently to different situations, 
                    disagreements are entirely understood, but respect that moderation staff have the final verdict.
                    <br/><br/>
                    Ignorance of rules is not an excuse for breaking them. Utilising loopholes in the rules is not allowed.
                </p>
                <hr/>
                <h2>Common Definitions</h2>
                <p className="Rules-intro">
                    Mechanics - Actual features/gameplay/plugins in the game<br/>
                    RP - Roleplay actions and IC dialogue between players<br/>
                    Lore - Written down/spoken nation/character history and background, with no direct effect on player interaction<br/>
                </p>
                <div id="Rules-container">Loading rules...</div>
            </div>
        </>
    );
}

export default HtmlRules;