const defaultText = 'Copy Server IP';

function buttonTextChange(text) {
    document.getElementById('serverIpButton').innerText = text;
    setTimeout(function() {
        document.getElementById('serverIpButton').innerText = defaultText;
    }, 2000);
}

function copyClick() {
    var copy = navigator.clipboard.writeText('play.realmofrhaya.com');
    copy.then(function() {
        buttonTextChange('Copied to clipboard');
    }, function() {
        buttonTextChange('Failed to copy');
    });
}

function ServerIpButton()
{
    return (
        <div>
            <button id="serverIpButton" onClick={copyClick}>{defaultText}</button>
        </div>
    );
}


export default ServerIpButton;